import React from 'react';
import Tabs from '../../Component/Tabs';
import Modal from '../../Component/Modal';
import Form from '../../Component/Form';
import LabelH from '../../Component/LabelH';
import LabelM from '../../Component/LabelM';
import moment from 'moment';
import service from './service';
import servicesEmployee from '../Employee/service';
import LoadingTable from '../../Component/LoadingTable';

/*
    TODO:
    > Map response employee role,status,level,title to Update payload (ex Manager to ER001)
    >> [DONE] with note highly dependent with different backend data entities
    > Integrate with BE (UPDATE Data)
    >> [DONE]
    > Integrate with BE (DELETE Data)
    >> [DONE]
    > Show modal when data will be deleted
    >> [DONE]
*/
class ProjectDetail extends React.Component {
    state = {
        salesActive : [],
        client : "",
        projectEndDate : 0,
        remark : "",
        isSupport : false,
        name : "",
        noKontrak : "",
        nilai : "",
        sales : "",
        salesCode : "",
        projectStartDate : "",
        redirect : false,
        showDeleteModal : false,
        showExtendModal : false,
        version: 0,
        projectHistory : [],
        extendDate : 0,
        reason : "",
        fileDocument: '',
    }

    onChange = (event) => {
        const target = event.target;
        const name = target.name;
        let isSuccess = 1;
        let message = '';
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            case 'file':
                const data = new FormData();
                data.append('file', target.files[0]);
                data.append('fileType', target.name);
                data.append('id', this.props.match.params.id);

                if (target.files[0].size > 1000000) {
                    isSuccess = 0;
                    message = 'Please upload less than 1 MB'
                } else {
                    service.uploadFile(data)
                    .then(res => {
                        if(res.status===200){
                            value = res.data.result;
                            this.setState({
                                [name]: value
                            });
                        } else {
                            console.log(res)  
                        }
                    })
                    .catch(err => {
                        alert("Failed");
                    })
                }

                break;
            default :
                value = target.value;
        }

        if (isSuccess) {
            this.setState({
                [name]: value
            });
        } else {
            alert(message)
        }
    }

    onDelete = (event) => {
        event.preventDefault();
        service.deleteProjectById(this.props.match.params.id)
        .then(res => {
            if(res.status===200){
                // display modal good
                alert("Data terhapus")
                this.props.history.goBack();
            } else {
                // display modal bad
                alert("Gagal")
            }
        })
        .catch(err => {
            alert("Gagal");
        })
    }

    onSubmit = (event) => {
        // Integrate with BE
        event.preventDefault();
        service.putProject({
            client : this.state.client.client,
            end_date : this.state.projectEndDate,
            end_date_remark : this.state.remark,
            is_support : this.state.isSupport,
            name : this.state.name,
            no_kontrak : this.state.noKontrak,
            nilai : this.state.nilai,
            sales: this.state.salesCode ? this.state.salesCode : this.state.sales ? this.state.salesActive.find(e => e.name === this.state.sales).id : null,
            start_date : this.state.projectStartDate,
            version: this.state.version
        }, this.props.match.params.id)
        .then(res => {
            if(res.status===200){
                // display modal good
                this.props.history.goBack();
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch(err => {
            alert("Fail");
        });
    }

    onExtend = (event) => {
        // Integrate with BE
        service.extendProject({
            id : this.props.match.params.id,
            extend_date : this.state.extendDate,
            reason : this.state.reason,
        }).then(res => {
            if(res.status===200){
                // display modal good
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch(err => {
            alert("Fail");
        })
    }

    componentDidMount(){
        service.getProjectById(this.props.match.params.id)
            .then((res) => {
                let documents = res.data.result.Document;
                documents.map((data) => {
                    if (data.fileType === 'fileDocument') {
                        this.setState({fileDocument: data.url});
                    } 
                    return data;
                })
                this.setState({
                    client : res.data.result.client,
                    projectEndDate : res.data.result.end_date,
                    remark : res.data.result.end_date_remark ? res.data.result.end_date_remark : '',
                    noKontrak : res.data.result.no_kontrak ? res.data.result.no_kontrak : '',
                    nilai : res.data.result.nilai ? res.data.result.nilai : '',
                    sales: res.data.result.sales_name,
                    isSupport : res.data.result.is_support,
                    name : res.data.result.name,
                    projectStartDate : res.data.result.start_date,
                    version: res.data.result.version
                })
            });
            service.getProjectHistory(this.props.match.params.id).then(res => this.setState({projectHistory:[...res.data.result],isLoaded:true}));
            servicesEmployee.getSales().then(res => this.setState({salesActive:[...res.data.result]}));
    }

    render(){
        return(
            <div>
                <h4 className="inline">Project Data</h4>
                <button onClick={ (event) => {event.preventDefault();this.setState({ showDeleteModal:true })} } className="btn btn-danger btn-rounded pull-right m-l-10">
                    <i className="ti-trash"></i>                
                </button>
                <button onClick={ this.onSubmit } className="btn btn-primary btn-rounded pull-right m-l-10">
                    <i className="ti-save"></i>
                </button>
                <button onClick={ (event) => {event.preventDefault();this.setState({ showExtendModal:true })} } className="btn btn-info btn-rounded pull-right">
                    <i className="fa fa-arrows"></i>
                </button>
                <form onSubmit={ this.onSubmit } className="form-horizontal">
                    <Tabs>
                        <Tabs.Pane isActive={ true } tabs="Project" anchor="project">
                            <div className="col-md-6">
                                <LabelH htmlFor="projectName" label="Project Name">
                                    <input required type="text" className="form-control" id="projectName" placeholder="Project name" name="name" value={ this.state.name } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="projectStartDate" label="Start Date">
                                    <input required type="date" className="form-control" name="projectStartDate" value={ this.state.projectStartDate ? moment(this.state.projectStartDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="projectContract" label="Contract Number">
                                    <input required type="text" className="form-control" id="projectContract" name="noKontrak" value={ this.state.noKontrak } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="projectSales" label="Sales">
                                    <select required className="form-control" id="projectSales" name="salesCode" defaultValue="" onChange={ this.onChange } >
                                    <option disabled>{ this.state.sales }</option>
                                        { this.state.salesActive.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                    </select>
                                </LabelH>
                                <LabelH htmlFor="projectRemark" label="Remark">
                                    <textarea rows="4" id="projectRemark" className="form-control" name="remark"  value={ this.state.remark } onChange={ this.onChange } />
                                </LabelH>
                            </div>
                            <div className="col-md-6">
                                <LabelH htmlFor="projectClient" label="Client">
                                    <input required type="text" className="form-control" id="projectName" placeholder="Client name" name="client" value={ this.state.client } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="projectEndDate" label="End Date">
                                    <input disabled required type="date" className="form-control" name="projectEndDate" value={ this.state.projectEndDate ? moment(this.state.projectEndDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="projectValue" label="Price">
                                    <input required type="text" className="form-control" id="projectValue" name="nilai" value={ this.state.nilai } onChange={ this.onChange } />
                                </LabelH>
                                <LabelH htmlFor="fileDocument" label="File Document">
                                    {this.state.fileDocument && <a href={this.state.fileDocument} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                    <input type="file" name="fileDocument" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                </LabelH>
                                <LabelH htmlFor="projectSupportRadio" label="Support">
                                    <label className="radio-inline">
                                        <input type="checkbox" name="isSupport" id="projectSupportRadio" value="true" checked={ this.state.isSupport === true ? true : false } onChange={ this.onChange } /> Yes
                                    </label>
                                </LabelH>
                            </div>
                        </Tabs.Pane>
                        <Tabs.Pane tabs="History" anchor="history">
                        <div className="table-responsive m-t-10 col-md-6 col-md-offset-3">
                            <table className="table table-hover mails m-0">
                                <thead>
                                    <tr>
                                        <th>End Date Before</th>
                                        <th>End Date After</th>
                                        <th>Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.isLoaded ? this.state.projectHistory.map((e,index) => 
                                        <tr key={ index }> 
                                            <td>{ e.extend_date===null ? '-' : moment(e.extend_date).format("D MMM YYYY") }</td>
                                            <td>{ e.end_date_before===null ? '-' : moment(e.end_date_before).format("D MMM YYYY") }</td>
                                            <td>{ e.reason }</td>
                                        </tr>) : <LoadingTable colspan="4" />
                                    }
                                </tbody>
                            </table>
                        </div>
                        </Tabs.Pane>
                    </Tabs>
                </form>
                {
                    this.state.showDeleteModal
                    ? <Modal modalTitle="Delete Project" showModal={ this.state.showDeleteModal } onClose={ () => this.setState({ showDeleteModal:false })} hasConfirm={ true } hasCancel={ true } onConfirm={ this.onDelete }>
                        <center>Are you sure you want to delete <b>{ this.state.name }</b> ?</center>
                    </Modal>
                    : ''
                }
                {
                    this.state.showExtendModal ?
                    <Modal id="extendProject" confirmText="Extend" modalTitle="Extend Project" showModal={ this.state.showExtendModal } onClose={ () => this.setState({ showExtendModal:false })} >
                        <Form id="projectForm" onSubmit={ this.onExtend }>
                            <LabelM htmlFor="extendDate" label="Extend Date">
                                <input type="date" className="form-control" name="extendDate" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="reason" label="Reason">
                                <textarea required rows="2" id="reason" className="form-control" name="reason" onChange={ this.onChange } />
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default ProjectDetail;