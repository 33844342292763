import React, { Component } from 'react';

class TopBar extends Component {
    render () {
        return (
            <div>
                <div className="topbar-left shadow">
                    <div className="">
                        <a href="/" className="logo">
                            <img src="/images/logo.png" alt="logo" className="logo-lg" />
                            <img src="/images/logo_sm.png" alt="logo" className="logo-sm hidden" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopBar;