import React from 'react';
import App from './App';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './Login/Login';
import Auth from './config/auth';
import url from './config/urls'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.authenticate() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const fakeAuth = {
  isAuthenticated: false,
  authenticate() {
      if(Auth.getToken()!==null){
          this.isAuthenticated = true;
      }
      else{
          this.isAuthenticated = false;
      }
      return this.isAuthenticated;
  },
};

class Routes extends React.Component{
    
  constructor(props){
      super(props);
      this.state = {
          isAuthenticated:'',
      }
  }

  componentDidMount(){
      const token = localStorage.getItem('token');
      this.setState({isAuthenticated:token});
        
  }
  render(){
    return(
        <Router>
            <div style={{height:'100%'}}>
                <Route exact path="/" component={Login} />
                <PrivateRoute path={ "/"+url.ADM } component={App} />
            </div>
        </Router>
    )
  }
}

export default Routes;