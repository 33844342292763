import React, { Component } from 'react';
import Form from '../Component/Form';
import Auth from '../config/auth';
import url from '../config/urls';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError : false,
        }
    }

    onSubmit = (data) =>{
        // Do not store token on localstorage or sessionStorage
        // https://auth0.com/docs/security/store-tokens#don-t-store-tokens-in-local-storage
        Auth.fetchAndStoreToken({
            username : data.username,
            password : data.password
        }, () => this.props.history.push('/administration'), () => this.setState({ showError:true })
        );
    }

    componentDidMount(){
        if (Auth.getToken()!==null){
            this.props.history.push("/"+url.ADM);
        }
    }

    render () {
        return (
            <section>
                <div className="container">
                    <div className="col-sm-12">
                        <div className="wrapper-page">
                            <div className="m-t-40 card-box">
                                <h2 className="text-uppercase m-t-0 m-b-30 text-center">
                                    <img src="/images/logo.png" alt="logo" className="logo-lg" />
                                </h2>
                                <div className="account-content">
                                    <Form className="form-horizontal" id="projectForm" onSubmit={ this.onSubmit }>
                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                <label htmlFor="username">Username</label>
                                                <input className="form-control" type="text" name="username" required placeholder="Enter your username" />
                                            </div>
                                        </div>
                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                <label htmlFor="password">Password</label>
                                                <input className="form-control" type="password" name="password" required placeholder="Enter your password"/>
                                            </div>
                                        </div>
                                        <div className="form-group account-btn text-center m-t-10">
                                            <div className="col-xs-12">
                                                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign In</button>
                                            </div>
                                        </div>
                                    </Form>
                                    {
                                        this.state.showError &&
                                        <div className="col-md-12 center-text text-danger"><b>Wrong Username or Password</b></div>
                                    }
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Login
