import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './service';
import Form from '../../Component/Form';
import Modal from '../../Component/Modal';
import Pagination from '../../Component/Pagination';
import LoadingTable from '../../Component/LoadingTable';
import LabelM from '../../Component/LabelM';

class Project extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            pages : 1,
            project : [],
            showModal : false
        }
    }

    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    signal = Axios.CancelToken.source();

    componentDidMount() {
        service.getProjectActive().then(res => this.setState({project:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }

    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    onPageChanges = (page) => {
        this.setState({ isLoaded:false })
        service.getProjectActive({
            page : page - 1
        }).then((res) => this.setState({project:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }

    onSubmit = (event) => {
        this.setState({ showModal:false })
        service.postProject({
            name: this.state.name,
            client: this.state.client,
            end_date: this.state.projectEndDate,
            is_support: this.state.isSupport,
            start_date: this.state.projectStartDate,
            end_date_remark: this.state.remark,
        })
        .then((res) => {
            if(res.status===200){
                // display modal good
                // alert("Success")
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }

    onTableClickHandler = (id) => {
        this.props.history.push(`${ this.props.match.path }/${ id }`);
    }

    render (){
        return (
            <div>
                <h4 className="inline">Project</h4>
                <button className="btn btn-info btn-rounded pull-right" onClick = { () => this.setState({showModal:true}) }>
                    <i className="ti-plus"></i>
                </button>
                <div className="table-responsive m-t-10">
                    <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.isLoaded ? this.state.project.map((e,index) => 
                                <tr onClick={ () => this.onTableClickHandler(e.id) } key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.client }</td>
                                    <td>{ e.name }</td>
                                    <td>{ e.start_date===null ? '-' : moment(e.start_date).format("D MMM YYYY") }</td>
                                    <td>{ e.end_date===null ? '-' : moment(e.end_date).format("D MMM YYYY") }</td>
                                </tr>) : <LoadingTable colspan="4" />
                            }
                        </tbody>
                    </table>
                    <Pagination totalPages={ this.state.pages } onChange={ this.onPageChanges } />
                </div>
                {
                    this.state.showModal ? 
                    <Modal id="addProject" confirmText="Create" modalTitle="Add Project" showModal={ this.state.showModal } onClose={ () => { this.setState({ showModal:false }) } } >
                        <Form id="projectForm" onSubmit={ this.onSubmit }>
                            <LabelM htmlFor="projectName" label="Project Name">
                                <input required type="text" className="form-control" id="projectName" name="name" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="projectClient" label="Client">
                                <input required type="text" className="form-control" id="projectName" name="client" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="projectStartDate" label="Start Date">
                                <input required type="date" className="form-control" name="projectStartDate" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="projectEndDate" label="End Date">
                                <input type="date" className="form-control" name="projectEndDate" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="projectSupport" label="Is Support">
                                <label className="radio-inline">
                                    <input type="checkbox" name="isSupport" id="employeeGenderRadio" value="true" onChange={ this.onChange }/> Support
                                </label>
                            </LabelM>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </Form>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default Project;