import React from 'react';

class Tabs extends React.Component {
    state = {...this.props};

    onSelect = (key) => {
        this.setState({
            selectedKey:key
        })
    }

    render(){
        return(
            <div className='tabs'>
                <ul className="nav nav-tabs tabs-bordered nav-justified">
                    { this.props.children.map( (e,index) => <li onClick={ () => this.onSelect(index+1) } data-toggle="tab" key={ index } className={ e.props.isActive ? "active" : "" }><a href="#page-wrapper">{ e.props.tabs }</a></li> ) }
                </ul>
                <div className="tab-content">
                    { this.props.children.map( (e,index) => <Tabs.Pane isActive={ this.state.selectedKey===index+1 } key={ index }>{ e.props.children }</Tabs.Pane>) }
                </div>
            </div>
        )
    }
}

Tabs.defaultProps = {
    selectedKey : 1
}

Tabs.Pane = (props) => {
    return(
        <div id={ props.anchor } className={`tab-pane fade in${ props.isActive ? " active" : '' }`}>
            { props.children }
        </div>
    )
}

Tabs.Pane.defaultProps = {
    tabs : "Tab",
    isActive : false
}

export default Tabs;