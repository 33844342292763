import React, { Component } from 'react';

class CircleText extends Component {
    render () {
        var hue = Math.floor(Math.random() * 360);
        var pastel = 'hsl(' + hue + ', 70%, 65%)';

        const pStyle = {
            display: 'inline-block',
            width: this.props.size + 'px',
            height: this.props.size + 'px',
            borderRadius: '50%',
            textAlign: 'center',
            color: 'white',
            fontSize: (this.props.size / 3) + 'px',
            lineHeight: this.props.size + 'px',
        
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: pastel
        };

        return (
            <div style={pStyle}>
                { this.props.name }
            </div>
        )
    }
}

export default CircleText;