import WebService from '../../WebService';
import URL from '../../config/urls';

const deleteProjectById = (id) => {
    return WebService.delete(`${ URL.PROJECT }/${ id }`);
}

const getProject = (params) => {
    return WebService.get(URL.PROJECT, {
        params : params
    });
}

const getProjectActive = (params) => {
    return WebService.get(`${URL.PROJECT}/${ URL.ACTIVE }`, {
        params : params
    });
}

const getResourceActive = (params) => {
    return WebService.get(`${URL.PROJECT_RESOURCE}/${ URL.ACTIVE }`, {
        params : params
    });
}

const getProjectHistory = (id) => {
    return WebService.get(`${URL.PROJECT}/${ URL.HISTORY }/${id}`, {
        
    });
}

const getProjectById = (id) => {
    return WebService.get(`${ URL.PROJECT }/${ id }`);
}

const postProject = (data) => {
    return WebService.post(URL.PROJECT, data);
}

const putProject = (data,id) => {
    return WebService.put(`${ URL.PROJECT }/${ id }`, data);
}

const extendProject = (data) => {
    return WebService.post(`${ URL.PROJECT }/${ URL.EXTEND }`, data);
}

const postResource = (data) => {
    return WebService.post(URL.PROJECT_RESOURCE, data);
}

const putResource = (data,id) => {
    return WebService.put(`${ URL.PROJECT_RESOURCE }/${ id }`, data);
}

const uploadFile = (data) => {
    return WebService.post(`${ URL.DOCUMENT }/${ URL.UPLOAD }`, data);
}

export default {getProject,getProjectActive,getResourceActive,getProjectById,
    postProject,putProject,postResource,putResource,extendProject,deleteProjectById,getProjectHistory, uploadFile};