import React from 'react';

/*
    NOTE:
    > How to use
    >> Using state as condition whether Modal component will be mount or unmount
    TODO:
    > Modal visibility control [done]
    > Self control, independent with parent
*/
class Modal extends React.Component {
    modalTitle = this.props.modalTitle;
    cancelText = this.props.cancelText || "Cancel";
    confirmText = this.props.confirmText || "OK";
    hasCancel = this.props.hasCancel || false;
    hasConfirm = this.props.hasConfirm || false;

    componentDidMount(){
        if(this.props.showModal){
            document.querySelector('body').classList.add('modal-open');
            document.querySelector('body').setAttribute('style', 'padding-right:15px');
        }else {
            document.querySelector('body').classList.remove('modal-open');
            document.querySelector('body').setAttribute('style', 'padding-right:inherit');
        }
    }

    componentWillUnmount(){
        document.querySelector('body').classList.remove('modal-open');
        document.querySelector('body').setAttribute('style', 'padding-right:inherit');        
    }

    render(){
        return(
            <div>
                { this.props.showModal ? 
                    <div id={ this.props.id } className={ this.props.showModal ? "modal fade in" : "modal fade"} tabIndex="-1" role="dialog" aria-hidden="true" style={ this.props.showModal ? {display:"block"} : {}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="inline">{ this.modalTitle }</h4>
                                    <button type="button" className="close" aria-label="Close" onClick={ this.props.onClose }>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    { this.props.children }
                                </div>
                                {
                                    this.hasCancel || this.hasConfirm
                                    ? <div className="modal-footer">
                                        { this.hasCancel ? <button onClick={ this.props.onClose } type="button" className="btn btn-secondary">{ this.cancelText }</button> : '' }
                                        { this.hasConfirm ? <button onClick={ this.props.onConfirm } type="button" className="btn btn-primary">{ this.confirmText }</button> : '' }
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    : ''
                }
                { this.props.showModal ? <div className="modal-backdrop fade in"></div> : '' }
            </div>
        )
    }
}

Modal.defaultProps = {
    showModal : false,
    onClose : () => {}
};

export default Modal;