import React from 'react';

const LEFT_PAGE = 'LEFT_PAGE';
const LEFT_MORE = 'LEFT_MORE';
const RIGHT_PAGE = 'RIGHT_PAGE';
const RIGHT_MORE = 'RIGHT_MORE';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while(i <= to){
        range.push(i);
        i += step;
    }

    return range;
}

class Pagination extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentPage : 1
        }
        this.onChange = this.props.onChange;
    }
    // totalPages
    // currentPage
    // pageSize
    // < ... [page] ... >
    // prev | more with prevDispay on over | pages | more with nextDisplay on hover | next

    // initPages();
    // onClick on prev, next, prevDisp, nextDips

    // Setiap item akan di-anggap sebagai blok

    defaultOnChange = (index) => {
        this.setState({ currentPage:index })
        if(typeof this.onChange === 'function') this.onChange(index);
    }

    prevItem = (step = 1) => {
        this.setState({
            currentPage : this.state.currentPage - 1
        })
    }

    nextItem = (step = 1) => {
        this.setState({
            currentPage : this.state.currentPage + 1
        })
    }

    getPages = () => {
        let totalPages = this.props.totalPages || 1;
        let currentPage = this.state.currentPage;
        // Total visible pages
        let totalSidePages = this.props.totalSidePages || 2;
        // Total block is visible pages with control block (<) and (>)
        let totalBlocks = totalSidePages*2 + 2;

        if(totalPages > totalBlocks){
            let startPage = Math.max(2, currentPage - totalSidePages);
            let endPage = Math.min(totalPages - 1, currentPage + totalSidePages);
            let paginationBlock = range(startPage, endPage, 1);   // get range
        
            let hasLeft = startPage > 2;
            let hasRight = endPage < totalPages - 1;
            // 3 cases
            // [1] Ujung kiri 1 2 3 ... 8 >
            // [2] Pertengahan < 1 ... 3 4 5 ... 8 >
            // [3] Ujung kanan < 1 ... 6 7 7
            switch(true){
                case !hasLeft && hasRight:
                    return [1, /* pages */...paginationBlock, RIGHT_MORE, totalPages, RIGHT_PAGE];
                case hasLeft && !hasRight:
                    return [LEFT_PAGE, 1, LEFT_MORE, /* pages */...paginationBlock, totalPages]
                case hasLeft && hasRight:
                    return [LEFT_PAGE, 1, LEFT_MORE, /* pages */...paginationBlock, RIGHT_MORE, totalPages, RIGHT_PAGE];
                default:
                    return [1, /* pages */...paginationBlock, totalPages];
            }
        } else {
            return range(1, totalPages);
        }
    }
    
    render(){
        const pageBlocks = this.getPages();

        return(
            <nav aria-label="pagination">
                <ul className='pagination justify-content-center"'>
                {
                    pageBlocks.map( e => {
                        if(LEFT_MORE === e)
                            return (
                                <li key={ e } className='page-item disabled'><a className='page-link' href='#page-wrapper'>...</a></li>
                            )
                        else if(LEFT_PAGE === e)
                            return (
                                <li key={ e } className='page-item'><a onClick={ this.prevItem } className='page-link' href='#page-wrapper' aria-label='Previous'><span aria-hidden="true">&laquo;</span><span className="sr-only">Previous</span></a></li>
                            )
                        else if(RIGHT_MORE === e)
                            return (
                                <li key={ e } className='page-item disabled'><a className='page-link' href='#page-wrapper'>...</a></li>
                            )
                        else if(RIGHT_PAGE === e)
                            return (
                                <li key={ e } className='page-item'><a onClick={ this.nextItem } className='page-link' href='#page-wrapper' aria-label='Next'><span aria-hidden="true">&raquo;</span><span className="sr-only">Next</span></a></li>
                            )
                        else
                            return (
                                <li key={ e } className={ e===this.state.currentPage?'page-item active' : 'page-item' }><a onClick={ () => {this.defaultOnChange(e)} } className='page-link' href='#page-wrapper'>{ e }</a></li>
                            )
                    })
                }
                </ul>
            </nav>
        )
    }
}

export default Pagination;