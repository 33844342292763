import React, { Component } from 'react';

class LabelH extends Component {
    render () {
        return (
            <div className="form-group">
                <label htmlFor={this.props.htmlFor} className="col-md-4 control-label">{this.props.label}</label>
                <div className="col-md-8">
                    { this.props.children }
                </div>
            </div>
        )
    }
}

export default LabelH;