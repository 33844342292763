import React, { Component } from 'react';

class Footer extends Component {
    render () {
        return (
            <div className="footer">
                © <b>Nostra 2.0</b> - <a href="http://github.com/Rukaan/">Rukaan</a> Innovation Day. All rights reserved
            </div>
        )
    }
}

export default Footer;