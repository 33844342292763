import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './service';
import serviceEmployee from './../Employee/service';
import Form from '../../Component/Form';
import Modal from '../../Component/Modal';
import Pagination from '../../Component/Pagination';
import LoadingTable from '../../Component/LoadingTable';
import LabelM from '../../Component/LabelM';

class Resource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resources : [],
            employeeActive : [],
            projectActive : [],
            roleOption : [],
            id : "",
            version : 0,
            employee : "",
            project : "",
            role : "",
            startDate : 0,
            endDate : 0,
            isLoaded : false,
            pages : 1,
            showModal : false,
            modalState : "Add",
        }
    }

    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    } 

    signal = Axios.CancelToken.source();

    componentDidMount(){
        service.getResourceActive().then((res) => this.setState({resources:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
        serviceEmployee.getEmployeeActive({
            limit : 9999
        }).then((res) => this.setState({employeeActive:[...res.data.result.listData]}));
        service.getProjectActive({
            limit : 9999,
            sortBy : "name",
            direction : "asc"
        }).then((res) => this.setState({projectActive:[...res.data.result.listData]}));
        serviceEmployee.getRoleParameters()
            .then((res) => this.setState({ roleOption : [...res.data.result] }));
    }

    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    onPageChanges = (page) => {
        this.setState({ isLoaded:false })
        service.getResourceActive({
            page : page - 1
        }).then((res) => this.setState({resources:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }

    onCreateModalShow = () => {
        this.setState({ 
            showModal: true, 
            modalState: "Add",
            id: "",
            version: 0,
            project: "",
            projectCode: "",
            employee: "",
            employeeCode: "",
            role: "",
            roleCode: "",
            startDate: 0,
            endDate: 0,
        })
    }

    onUpdateModalShow = (data) => {
        this.setState({ 
            showModal: true, 
            modalState: "Update",
            id: data.id,
            version: data.version,
            project: data.project_name,
            projectCode: data.project_id,
            employee: data.employee_name,
            employeeCode: data.employee_id,
            role: data.role_name,
            roleCode: data.role_id,
            startDate: data.start_date,
            endDate: data.end_date,
        })
    }

    onSubmit = (event) => {
        // Integrate with BE
        this.setState({ showModal:false })
        this.state.modalState==="Add" ? this.addResource() : this.updateResource()
    }

    addResource = () => {
        // Integrate with BE
        service.postResource({
            project_id: this.state.projectCode ? this.state.projectCode : this.state.project ? this.state.projectActive.find(e => e.name === this.state.project).id : null,
            employee_id: this.state.employeeCode ? this.state.employeeCode : this.state.employee ? this.state.employeeActive.find(e => e.name === this.state.employee).id : null,
            role_id: this.state.roleCode ? this.state.roleCode : this.state.role ? this.state.roleOption.find(e => e.name === this.state.role).id : null, 
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        })
        .then((res) => {
            if(res.status===200){
                // display modal good
                // alert("Success")
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }

    updateResource = () => {
        // Integrate with BE
        service.putResource({
            project_id: this.state.projectCode ? this.state.projectCode : this.state.project ? this.state.projectActive.find(e => e.name === this.state.project).id : null,
            employee_id: this.state.employeeCode ? this.state.employeeCode : this.state.employee ? this.state.employeeActive.find(e => e.name === this.state.employee).id : null,
            role_id: this.state.roleCode ? this.state.roleCode : this.state.role ? this.state.roleOption.find(e => e.name === this.state.role).id : null, 
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            version: this.state.version,
        }, this.state.id)
        .then((res) => {
            if(res.status===200){
                // display modal good
                // alert("Success")
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }

    render (){
        return (
            <div>
                <h4 className="inline">Resource</h4>
                <button className="btn btn-info btn-rounded pull-right" onClick={ this.onCreateModalShow }>
                    <i className="ti-plus"></i>
                </button>
                <div className="table-responsive m-t-10">
                    <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Employee</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Role</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.isLoaded ? this.state.resources.map((e,index) => 
                                <tr key={ index }>
                                    <td>{ e.project_name }</td>
                                    <td>{ e.employee_name }</td>
                                    <td>{ e.start_date ? moment(e.start_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.end_date ? moment(e.end_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.role_name }</td>
                                    <td style={{ padding:'3px' }}>
                                        <button className="btn btn-success btn-sm btn-rounded" onClick={ () => this.onUpdateModalShow(e) }>
                                            <i className="ti-pencil"></i>
                                        </button>
                                    </td>
                                </tr>
                                ) : <LoadingTable colspan="6" />
                            }
                        </tbody>
                    </table>
                    <Pagination totalPages={ this.state.pages } onChange={ this.onPageChanges } />
                </div>
                {
                    this.state.showModal ? 
                    <Modal id="addResource" confirmText="Create" modalTitle={ this.state.modalState+" Resource" } showModal={ this.state.showModal } onClose={ () => { this.setState({ showModal:false }) } } >
                        <Form id="resourceForm" onSubmit={ this.onSubmit }>
                            <LabelM htmlFor="projectName" label="Project">
                                <select required className="form-control" id="projectName" name="projectCode" defaultValue="" onChange={ this.onChange } disabled= { this.state.modalState==="Update" } >
                                <option value={ this.state.projectCode } >{ this.state.project }</option>
                                    { this.state.projectActive.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeName" label="Employee">
                                <select required className="form-control" id="employeeName" name="employeeCode" defaultValue="" onChange={ this.onChange } disabled= { this.state.modalState==="Update" } >
                                <option value={ this.state.employeeCode } >{ this.state.employee }</option>
                                    { this.state.employeeActive.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeRole" label="Role">
                                <select required className="form-control" id="employeeRole" name="roleCode" defaultValue="" onChange={ this.onChange } >
                                <option value={ this.state.roleCode } >{ this.state.role }</option>
                                    { this.state.roleOption.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="resourceStartDate" label="Start Date">
                                <input type="date" className="form-control" id="resourceStartDate" name="startDate" value={ this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="resourceEndDate" label="End Date">
                                <input type="date" className="form-control" id="resourceEndDate" name="endDate" value={ this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange }/>
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default Resource;