import React, { Component } from 'react';
import Auth from '../config/auth'

class SideBar extends Component {
    username = () => {
        let key = Auth.decodeBase64(Auth.getToken());
        return key.substring(0,key.indexOf(":"));
    }

    render () {
        return (
            <aside className="sidebar-navigation shadow">
                <div className="scrollbar-wrapper">
                    <div>
                        <button type="button" className="button-menu-mobile btn-mobile-view visible-xs visible-sm">
                            <i className="mdi mdi-close"></i>
                        </button>
                        <div className="user-details">
                            <div className="pull-left">
                                <img alt="user-img" className="thumb-md img-circle" id="user-img" src="/images/admin.png"/>
                            </div>
                            <div className="user-info">
                                <p id="username">{ this.username() }</p>
                                <p className="text-muted m-0" id="role">Administrator</p>
                            </div>
                        </div>
                        <ul className="metisMenu nav" id="side-menu">
                            {this.props.children}
                        </ul>
                    </div>
                </div>
            </aside>
        )
    }
}

export default SideBar;