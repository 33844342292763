export default {
    ADM : 'administration',
    EMPLOYEE : 'employee',
    ROLE : 'role',
    ACTIVE : 'active',
    RESIGN : 'resign',
    LEAVE : 'leave',
    LEAVE_MONTH : 'leave-by-month',
    LEAVE_DETAIL : 'leave-detail',
    DELETE_LEAVE : 'delete-leave',
    ADD : 'add',
    UPLOAD : 'upload',
    BIRTHDAY : 'birthday',
    LOGIN : 'login',
    MASTER_LOV : 'master-lov',
    PROJECT : 'project',
    PROJECT_RESOURCE : 'project-resources',
    UNASSIGNED : 'unassigned',
    EXTEND : 'extend',
    HISTORY : 'history',
    DOCUMENT : 'document',
    DELETE_LEAVE_BY_EMPLOYEE: 'delete-leave-employee',
    UPDATE_LEAVE_BY_EMPLOYEE: 'update-leave-employee',
    TAX: 'tax'   
}