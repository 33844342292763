import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './service';
import Pagination from '../../Component/Pagination';
import Modal from '../../Component/Modal';
import Form from '../../Component/Form';
import LoadingTable from '../../Component/LoadingTable';
import LabelM from '../../Component/LabelM';
import url from '../../config/urls';

class Resign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees : [],
            employeeActive : [],
            employee : "",
            endDate : 0,
            reason : "",
            isLoaded : false,
            pages : 1,
            showModal : false
        }
    }

    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    filterList = (event) => {
        const target = event.target;

        this.getListEmployee(target.value);
    }

    signal = Axios.CancelToken.source();

    getListEmployee = (name) => {
        service.getEmployeeResign({name}).then((res) => this.setState({employees:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }

    componentDidMount(){
        this.getListEmployee();
    }

    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    onPageChanges = (page) => {
        this.setState({ isLoaded:false })
        service.getEmployeeResign({
            page : page - 1
        }).then((res) => this.setState({employees:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }
    
    onSubmit = (event) => {
        // Integrate with BE
        this.setState({ showModal:false })
        service.postResignEmployee({
            employee: this.state.employeeCode || this.state.employeeActive.find(e => e.name === this.state.employee).id,
            end_date: this.state.endDate,
            reason: this.state.reason,
        })
        .then((res) => {
            if(res.status===200){
                // display modal good
                // alert("Success")
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }

    onTableClickHandler = (id) => {
        this.props.history.push(`/${ url.ADM }/${ url.EMPLOYEE }/${ id }`);
    }

    onCreateModalShow = () => {
        this.setState({ showModal:true })
        service.getEmployeeActive({
            limit : 9999
        }).then((res) => this.setState({employeeActive:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages}));
    }

    render (){
        return (
            <div>
                <h4 className="inline">Resigned Employee</h4>
                <button className="btn btn-info btn-rounded pull-right" onClick={ this.onCreateModalShow }>
                    <i className="ti-plus"></i>
                </button>
                <form className="m-t-10">
                    <fieldset className="form-group">
                        <input type="text" className="form-control form-control-lg" placeholder="Search" onChange={this.filterList}/>
                    </fieldset>
                </form>
                <div className="table-responsive m-t-10">
                    <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Join Date</th>
                                <th>Resign Date</th>
                                <th>Title</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.isLoaded ? this.state.employees.map((e,index) => 
                                <tr onClick={ () => this.onTableClickHandler(e.id) } key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.name }</td>
                                    <td>{ e.join_date ? moment(e.join_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.end_date ? moment(e.end_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.title }</td>
                                    <td>{ e.reason }</td>
                                </tr>
                                ) : <LoadingTable colspan="5" />
                            }
                        </tbody>
                    </table>
                    <Pagination totalPages={ this.state.pages } onChange={ this.onPageChanges } />
                </div>
                {
                    this.state.showModal ? 
                    <Modal id="addEmployee" confirmText="Create" modalTitle="Employee Resign" showModal={ this.state.showModal } onClose={ () => { this.setState({ showModal:false }) } } >
                        <Form id="employeeForm" onSubmit={ this.onSubmit }>
                            <LabelM htmlFor="employeeName" label="Name">
                                <select required className="form-control" id="employeeName" name="employeeCode" defaultValue="" onChange={ this.onChange }>
                                <option disabled></option>
                                    { this.state.employeeActive.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeEndDate" label="Resign Date">
                                <input required type="date" className="form-control" id="employeeEndDate" name="endDate" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="employeeReason" label="Reason">
                                <input required type="text" className="form-control" id="employeeReason" name="reason" onChange={ this.onChange }/>
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default Resign;