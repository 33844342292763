import React from 'react';

/*
    TODO:
    > Fetch input values independent with parent
*/
class Form extends React.Component {

    handleInputChange(event) {
        const target = event.target;
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

    onSubmit = (event) => {
        if(this.props.onSubmit && typeof this.props.onSubmit === 'function') this.props.onSubmit(this.state);
        event.preventDefault();
    }

    render(){
        const children = React.Children.map(this.props.children, child => React.cloneElement(child, { onChange: this.handleInputChange.bind(this) }));
        return(
            <form id={ this.props.id } onSubmit={ this.onSubmit } className={ this.props.className }>
                { children } 
            </form>
        )
    }
}

export default Form;