import WebService from '../WebService';
import URL from './urls';

// Store token in-memory
// https://auth0.com/docs/security/store-tokens
var token = "";

const fetchAndStoreToken = (data, cb, wrongCb) => {
    WebService.post(URL.LOGIN, data)
    .then(res => {
        if(res.status === 200 && res.data.message === "OK"){
            token = res.data.result;
            WebService.defaults.headers.common['Authorization'] = token;
            localStorage.setItem('token',res.data.result);
            if(cb && typeof cb === 'function') cb();
        } else {
            if(wrongCb && typeof wrongCb === 'function') wrongCb();
        }
    })
}

const getToken = () => {
    return localStorage.getItem('token');
}

const decodeBase64 = (s) =>{
    var e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;
    var A="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for(i=0;i<64;i++){e[A.charAt(i)]=i;}
    for(x=0;x<L;x++){
        c=e[s.charAt(x)];b=(b<<6)+c;l+=6;
        while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
    }
    return r;
};

export default {fetchAndStoreToken,getToken,decodeBase64};