import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './../Employee/service';
import LoadingTable from '../../Component/LoadingTable';

class Unassigned extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees : [],
            isLoaded : false,
        }
    }

    signal = Axios.CancelToken.source();

    componentDidMount(){
        service.getUnassignedEmployee().then((res) => this.setState({employees:[...res.data.result],isLoaded:true}));
    }

    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    render (){
        return (
            <div>
                <h4 className="inline">Unassigned Employee</h4>
                <div className="table-responsive m-t-10">
                    <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Join Date</th>
                                <th>Status</th>
                                <th>Title</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {      
                                this.state.isLoaded ? this.state.employees.map((e,index) => 
                                <tr key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.name }</td>
                                    <td>{ e.join_date ? moment(e.join_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.status }</td>
                                    <td>{ e.title }</td>
                                    <td>{ e.role }</td>
                                </tr>
                                ) : <LoadingTable colspan="5" />
                            }
                        </tbody>
                    </table>              
                </div>
            </div>
        )
    }
}

export default Unassigned;