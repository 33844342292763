import React, { Component } from 'react';
import { Redirect, Route, Link, Switch } from 'react-router-dom';
import TopBar from './Component/TopBar';
import SideBar from './Component/SideBar';
import PageTemplate from './Component/PageTemplate';
import Employee from './Dashboard/Employee/Employee';
import Resign from './Dashboard/Employee/Resign';
import Birthday from './Dashboard/Employee/Birthday';
import Leave from './Dashboard/Employee/Leave';
import EmployeeDetail from './Dashboard/Employee/EmployeeDetail';
import Project from './Dashboard/Project/Project';
import ProjectDetail from './Dashboard/Project/ProjectDetail';
import Resource from './Dashboard/Project/Resource';
import Unassigned from './Dashboard/Project/Unassigned';
import Auth from './config/auth';
import TaxCalculator from './Dashboard/TaxCalculator';

class App extends Component {

  logoutHandler = () => {
    localStorage.removeItem('token');
    this.props.history.push('/');
  }

  username = () => {
    let key = Auth.decodeBase64(Auth.getToken());
    return key.substring(0,key.indexOf(":"));
  }
  
  render() {
    return (
      <div>  
        <div id="page-wrapper">
          <TopBar />
          <div className="page-contentbar">
            <SideBar>
              { 
                (this.username()==="admin" || this.username()==="hr") &&
                <li>
                  <a href="#page-wrapper" aria-expanded="true"><i className="ti-user"></i>  Employee <span className="fa arrow"></span></a>
                  <ul className="nav-second-level nav" aria-expanded="true">
                    <li><Link to={ `${ this.props.match.path }/employee` }><i className="ti-id-badge"></i> Active</Link></li>
                    <li><Link to={ `${ this.props.match.path }/resign` }><i className="ti-eraser"></i> Resign</Link></li>
                    <li><Link to={ `${ this.props.match.path }/leave` }><i className="ti-calendar"></i> Leave</Link></li>
                    <li><Link to={ `${ this.props.match.path }/birthday` }><i className="fa fa-birthday-cake"></i> Birthday</Link></li>
                  </ul>
                </li>
              }
              { 
                (this.username()==="admin" || this.username()==="pm") &&
                <li>
                  <a href="#page-wrapper" aria-expanded="true"><i className="ti-blackboard"></i>  Project <span className="fa arrow"></span></a>
                  <ul className="nav-second-level nav" aria-expanded="true">
                  <li><Link to={ `${ this.props.match.path }/project` }><i className="ti-agenda"></i> Active</Link></li>
                  <li><Link to={ `${ this.props.match.path }/resource` }><i className="fa fa-users"></i> Resource</Link></li>
                  <li><Link to={ `${ this.props.match.path }/unassigned` }><i className="fa fa-user-times"></i> Unassigned</Link></li>
                  </ul>
                </li>
              }
              <li><Link to={ `${ this.props.match.path }/tax-calculator` }><i className="ti-money"></i> Tax Calculator</Link></li>
              <li><a href="#root" onClick={this.logoutHandler}><i className="ti-power-off"></i> Logout</a></li>
            </SideBar>
            <PageTemplate>
              <Switch>
                <Route path={ `${ this.props.match.path }/employee/:id` } component={EmployeeDetail} />
                <Route path={ `${ this.props.match.path }/employee` } component={Employee} />
                <Route path={ `${ this.props.match.path }/resign` } component={Resign} />
                <Route path={ `${ this.props.match.path }/birthday` } component={Birthday} />
                <Route path={ `${ this.props.match.path }/leave` } component={Leave} />
                <Route path={ `${ this.props.match.path }/project/:id` } component={ProjectDetail} />
                <Route path={ `${ this.props.match.path }/project` } component={Project} />
                <Route path={ `${ this.props.match.path }/resource` } component={Resource} />
                <Route path={ `${ this.props.match.path }/unassigned` } component={Unassigned} />
                <Route path={ `${ this.props.match.path }/tax-calculator` } component={TaxCalculator} />
                <Route path={ `${ this.props.match.path }/:id` } render={ (props) => <Redirect to={`${ this.props.match.path }/employee/${ props.match.params.id }`}/> }/>
                {
                  (this.username()==="admin" || this.username()==="hr") &&
                  <Route path={ `${ this.props.match.path }` } component={Employee} />
                }
                {
                  (this.username()==="pm") &&
                  <Route path={ `${ this.props.match.path }` } component={Project} />
                }
              </Switch>
            </PageTemplate>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
