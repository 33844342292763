import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './service';
import Pagination from '../../Component/Pagination';
import Modal from '../../Component/Modal';
import Form from '../../Component/Form';
import LoadingTable from '../../Component/LoadingTable';
import LabelM from '../../Component/LabelM';
import CircleText from '../../Component/CircleText';
import CirclePhoto from '../../Component/CirclePhoto';

/*
    TODO:
    > Kasus 1
    >> Select option index 0, tidak masuk nilainya ke callback function onSubmit.
    >> Form component
    >> Solusi sementara : append data ke default list data
    
    > Kasus 2
    >> Menampilkan popup message setelah form dikirim
*/
class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee : [],
            isLoaded : false,
            pages : 1,
            levelOption : [],
            statusOption : [],
            roleOption : [],
            titleOption : [],
            showModal : false
        }
    }

    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    filterList = (event) => {
        const target = event.target;

        this.getListEmployee(target.value);
    }

    signal = Axios.CancelToken.source();

    getListEmployee = (name) => {
        service.getEmployeeActive({name})
        .then((res) => 
            { if (res.data.message === "UNAUTHORIZED") {
                window.location.reload();
            } else {
                let listData = res.data.result.listData;

                listData.map((data) => {
                    var index = data.Document.findIndex(x => x.fileType === 'photoProfile');
                    if (index >= 0) {
                        data.photoProfile = data.Document[index].url;
                    }
    
                    let str = data.name;
                    var matches = str.match(/\b(\w)/g);
                    var acronym = matches.join('');
                    data.acronymName = acronym;

                    return data;
                })

                this.setState({employee:[...listData],isLoaded:true,pages:res.data.result.totalPages})}
            }
        );
    }

    componentDidMount(){
        this.getListEmployee();
    }

    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    onPageChanges = (page) => {
        this.setState({ isLoaded:false })
        service.getEmployeeActive({
            page : page - 1
        }).then((res) => {
            let listData = res.data.result.listData;

            listData.map((data) => {
                var index = data.Document.findIndex(x => x.fileType === 'photoProfile');
                if (index >= 0) {
                    data.photoProfile = data.Document[index].url;
                }

                let str = data.name;
                var matches = str.match(/\b(\w)/g);
                var acronym = matches.join('');
                data.acronymName = acronym;

                return data;
            })

            this.setState({employee:[...listData],isLoaded:true,pages:res.data.result.totalPages})
        });
    }
    
    onSubmit = (event) => {
        // Integrate with BE
        this.setState({ showModal:false })
        service.postEmployee({
            name: this.state.name,
            join_date: this.state.joinDate,
            level: this.state.levelCode || this.state.levelOption.find(e => e.name === this.state.level).code,   // Fetching restructure effect
            role: this.state.roleCode || this.state.roleOption.find(e => e.name === this.state.role).code,  // Fetching restructure effect
            status: this.state.statusCode || this.state.statusOption.find(e => e.name === this.state.status).code, // Fetching restructure effect
            title: this.state.titleCode || this.state.titleOption.find(e => e.name === this.state.title).code, // Fetching restructure effect
            is_male: this.state.gender && this.state.gender === 'male',
        })
        .then((res) => {
            if(res.status===200){
                // display modal good
                window.location.reload()
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }

    onTableClickHandler = (id) => {
        this.props.history.push(`${ this.props.match.path }/${ id }`);
    }

    onCreateModalShow = () => {
        this.setState({ showModal:true })
        service.getTitleParameters()
            .then((res) => this.setState({ titleOption : [...res.data.result] }));
        service.getRoleParameters()
            .then((res) => this.setState({ roleOption : [...res.data.result] }));
        service.getLevelParameters()
            .then((res) => this.setState({ levelOption : [...res.data.result] }));
        service.getStatusParameters()
            .then((res) => this.setState({ statusOption : [...res.data.result] }));
    }

    render (){
        return (
            <div>
                <h4 className="inline">Employee</h4>
                <button className="btn btn-info btn-rounded pull-right" onClick={ this.onCreateModalShow }>
                    <i className="ti-plus"></i>
                </button>
                <form className="m-t-10">
                    <fieldset className="form-group">
                        <input type="text" className="form-control form-control-lg" placeholder="Search" onChange={this.filterList}/>
                    </fieldset>
                </form>
                <div className="table-responsive m-t-10">
                    <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Name</th>
                                <th>Join Date</th>
                                <th>Status</th>
                                <th>Title</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.isLoaded ? this.state.employee.map((e,index) => 
                                <tr onClick={ () => this.onTableClickHandler(e.id) } key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.photoProfile ? <CirclePhoto size={'50'} src={e.photoProfile} /> : <CircleText size={'50'} name={e.acronymName} /> }</td>
                                    <td>{ e.name }</td>
                                    <td>{ e.join_date ? moment(e.join_date).format("D MMM YYYY") : '-'}</td>
                                    <td>{ e.status }</td>
                                    <td>{ e.title }</td>
                                    <td>{ e.role }</td>
                                </tr>
                                ) : <LoadingTable colspan="5" />
                            }
                        </tbody>
                    </table>
                    <Pagination totalPages={ this.state.pages } onChange={ this.onPageChanges } />
                </div>
                {
                    this.state.showModal ? 
                    <Modal id="addEmployee" confirmText="Create" modalTitle="Add Employee" showModal={ this.state.showModal } onClose={ () => { this.setState({ showModal:false }) } } >
                        <Form id="employeeForm" onSubmit={ this.onSubmit }>
                            <LabelM htmlFor="employeeName" label="Name">
                                <input required type="text" className="form-control" id="employeeName" name="name" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="employeeGender" label="Gender">
                                <label className="radio-inline" style={{marginLeft:'15px'}}>
                                    <input required type="radio" name="gender" value="male" onChange={ this.onChange }/> Male
                                </label>
                                <label className="radio-inline">
                                    <input required type="radio" name="gender" value="female" onChange={ this.onChange }/> Female
                                </label>
                            </LabelM>
                            <LabelM htmlFor="employeeJoinDate" label="Join Date">
                                <input required type="date" className="form-control" id="employeeJoinDate" name="joinDate" onChange={ this.onChange }/>
                            </LabelM>
                            <LabelM htmlFor="employeeStatus" label="Status">
                                <select required className="form-control" id="employeeStatus" name="statusCode" defaultValue="" onChange={ this.onChange }>
                                    <option disabled></option>
                                    { this.state.statusOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeTitle" label="Title">
                                <select required className="form-control" id="employeeTitle" name="titleCode" defaultValue="" onChange={ this.onChange }>
                                <option disabled></option>
                                    { this.state.titleOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeRole" label="Role">
                                <select required className="form-control" id="employeeRole" name="roleCode" defaultValue="" onChange={ this.onChange }>
                                    <option disabled></option>
                                    { this.state.roleOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <LabelM htmlFor="employeeLevel" label="Level">
                                <select required className="form-control" id="employeeLevel" name="levelCode" defaultValue="" onChange={ this.onChange }>
                                    <option disabled>{ this.state.level }</option>
                                    { this.state.levelOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default Employee;