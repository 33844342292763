import React, { Component } from 'react';

class CirclePhoto extends Component {
    render () {
        const pStyle = {
            display: 'inline-block',
            width: this.props.size + 'px',
            height: this.props.size + 'px',
            borderRadius: '50%',

            objectFit: 'cover',
        };

        return (
            <img alt="" src={this.props.src} style={pStyle} />
        )
    }
}

export default CirclePhoto;