import React, { Component } from 'react';
import Footer from './Footer';

class PageTemplate extends Component {
    render () {
        return (
            <div id="page-right-content" className="container shadow">
                <div className="col-sm-12">
                    <div className="card-box">
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default PageTemplate;