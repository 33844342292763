import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment';
import service from './service';
import Pagination from '../../Component/Pagination';
import Modal from '../../Component/Modal';
import Form from '../../Component/Form';
import LoadingTable from '../../Component/LoadingTable';
import LabelM from '../../Component/LabelM';


class Leave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded : false,
            maxLeave : 18,
            leaves : [],
            leavesByMonth : [],
            employeeActive : [],
            employee : "",
            leaveDate : [],
            leaveList:[],
            reason : "",
            pages : 1,
            detailView : true,
            detailViewMonth : false,
            months : [],
            monthsFullname : [],
            showConfirmModal: false,
            listLeaveView:false,
            pageOne:true,
            showConfirmModalDeleteLeave:false,
            paramId:"",
            showModalEditLeave:false,
            leaveDateData:[],
            reasonUpdate:"",
            dateUpdate:"",
            idLeave:"",
        }
    }

    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    filterList = (event) => {
        const target = event.target;

        this.getListLeave(target.value);
    }

    signal = Axios.CancelToken.source();

    componentDidMount(){
        this.setState({months:moment.monthsShort(),
            monthsFullname:[ 'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December' ]});
        
            this.getListLeave();
    }

    getListLeave = (name) => {
        service.getEmployeeActive({
            name
        }).then((res) => this.setState({employeeActive:[...res.data.result.listData]}));
        service.getEmployeeLeaves({name})
        .then(res => {
            this.setState({leaves:[...res.data.result.listData]})
        })
        service.getEmployeeLeavesByMonth({name})
        .then(res => {
            this.setState({leavesByMonth:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages})
        })
        
    }

    getListLeaveEmployee = (secureId) => {
        this.state.pageOne=false;
        service.getLeaveListDetail({id:secureId}).then(res => {
            this.setState({leaveList:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages})
        })
    }

        
    componentWillUnmount(){
        // Interrupt API call when component will unmount
        this.signal.cancel("API call has been canceled");
    }

    onPageChanges = (page) => {
        this.setState({ isLoaded:false })
        service.getEmployeeLeaves({
            page : page - 1
        })
        .then(res => {
            this.setState({leaves:[...res.data.result.listData]})
        })
        service.getEmployeeLeavesByMonth({
            page : page - 1
        })
        .then(res => {
            this.setState({leavesByMonth:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages})
        })
        
    }

    onPageChangeDetailLeave =  (page) => {
        service.getLeaveListDetail({page:page-1}).then(res => {
            this.setState({leaveList:[...res.data.result.listData],isLoaded:true,pages:res.data.result.totalPages})
        })
    }
    
    onCreateModalShow = () => {
        this.setState({ showModal:true })
    }

    onRefreshLeave = () => {
        this.setState({ showConfirmModal:true })
    }

    onRefresh = (event) => {
        event.preventDefault();
        service.refreshLeave()
        .then(res => {
            if(res.status===200){
                // display modal good
                this.setState({ showConfirmModal:false })
                this.getListLeave();
            } else {
                // display modal bad
                alert("Failed")
            }
        })
        .catch(err => {
            alert("Failed");
        })
    }

    onSubmit = (event) => {
        // Integrate with BE
        if (this.state.leaveDate.length == 0) {
            alert("List of leave date cannot be empty")
        } else {
            this.setState({ showModal:false })
            service.postLeave({
                id: this.state.employeeCode ? this.state.employeeCode : this.state.employee ? this.state.employeeActive.find(e => e.name === this.state.employee).id : null,
                leaveDateVOList: this.state.leaveDate,
                reason: this.state.reason,
            })
            .then((res) => {
                if(res.status===200){
                    // display modal good
                    window.location.reload()
                } else {
                    // display modal bad
                    alert("Fail")
                }
            })
            .catch((err) => {
                alert("Fail");
            });
        }
    }

    onDeeteLeave = (event) => {
        this.setState({showConfirmModalDeleteLeave:true,paramId:event})
    }

    onDeleteLeaveByEmployee = (e) => {
        e.preventDefault();
        this.setState({showConfirmModal:true})
        service.deleteLeaveByEmployee({id:this.state.paramId})
        .then((res)=> {
            if(res.status===200){
                window.location.reload()
            }else{
                alert('Fail');
            }
        })
    }

    onShowUpdateLeave = (e) => {
        console.log(e)
        this.setState({showModalEditLeave:true,reasonUpdate: e.reason, dateUpdate:e.leave_date, idLeave:e.id})
        
    }

    onUpdateLeave = () => {
        console.log(this.state.reasonUpdate+this.state.dateUpdate);
        service.updateLeaveByEmployee({
            id:this.state.idLeave,
            leave_date:this.state.dateUpdate,
            reason:this.state.reasonUpdate
            
        }).then((res)=>{
            if(res.status===200){
                window.location.reload()
            }else{
                alert('Fail')
            }
        })
        
    }

    onAddMoreLeaveDate = () => {
        let leaveDate = this.state.leaveDate;
        leaveDate.push({
            leaveDate: 0
        })
        this.setState({leaveDate});
    }

    onDeleteLeaveDate = (index) => {
        let leaveDate = this.state.leaveDate;
        leaveDate.splice(index, 1);
        this.setState({leaveDate});
    }

    onChangeLeaveDate = (event, index) => {
        const target = event.target;
        let value = new Date(target.value).getTime();
        
        let leaveDate = this.state.leaveDate;
        leaveDate[index].leaveDate = value;
        this.setState({leaveDate});
    }


    onChangeUpdateLeaveDate = (event) => {
        const target = event.target;
        let value = new Date(target.value).getTime();
        let leaveDate = this.state.leaveDate;
        console.log(leaveDate)
    }

    render (){
        return (
            <div>
                <h4 className="inline">Leave</h4>
                <button className="btn btn-info btn-rounded pull-right m-l-10" onClick={ this.onCreateModalShow }>
                    <i className="ti-plus"></i>
                </button>
                <button className="btn btn-success btn-rounded pull-right m-l-10" onClick={ () => this.setState({detailView: !this.state.detailView}) }>
                    <i className="ti-eye"></i>
                </button>
                <button className="btn btn-primary btn-rounded pull-right" onClick={ this.onRefreshLeave }>
                    <i className="ti-reload"></i>
                </button>
                <form className="m-t-10">
                    <fieldset className="form-group">
                        <input type="text" className="form-control form-control-lg" placeholder="Search" onChange={this.filterList}/>
                    </fieldset>
                </form>
                <div className="table-responsive m-t-10">
                {
                        !this.state.pageOne &&
                        <table className="table table-hover mails m-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Leave Date</th>
                                <th>Reason</th>
                                <th></th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.isLoaded ? this.state.leaveList.map((e,index) => 
                                <tr  key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.name }</td>
                                    <td>{ moment(e.leave_date).format("DD/MM/YYYY") }</td>
                                    <td>{ e.reason }</td>
                                    <td> <button className="btn btn-primary btn-rounded pull-right" onClick={ () => this.onDeeteLeave(e.id) }>
                                            <i className="ti-trash"></i>
                                         </button> 
                                            
                                         <button className="btn btn-primary btn-rounded pull-right" onClick={ () => this.onShowUpdateLeave(e) }>
                                            <i className="ti-pencil"></i>
                                         </button> 
                                    </td>
                                    
                                </tr>
                                 ): <LoadingTable colspan="5" />
                            }
                        </tbody>
                        <Pagination totalPages={ this.state.pages } onChange={ this.onPageChangeDetailLeave } />
                    </table>
                    
                }
                
                </div>
                {
                    this.state.pageOne &&
                    <div className="table-responsive m-t-10">
                    {
                        !this.state.detailView &&
                        <table className="table table-hover mails m-0">
                            <thead>
                                <tr>
                                    <th rowSpan="2" style={{ width:'200px' }}>Name</th>
                                    <th className="center-text" colSpan="18">Leave Taken</th>
                                </tr>
                                <tr>
                                {
                                    [...Array(this.state.maxLeave)].map((i,index) => <th key={ index } className="center-text"> { index+1 } </th>)
                                }                                    
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.isLoaded ? this.state.leaves.map((e,index) => 
                                <tr  key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.name }</td>
                                    { e.leaveList.map((day,index) => 
                                        <td key={ index } className="cell-leave center-text">{ day.leave_date ? moment(day.leave_date).format("D/M") : '-' }</td>
                                    )}
                                    {
                                        [...Array(this.state.maxLeave - e.leaveList.length)].map((i,index) => <td key={ index } className="center-text">  </td>)
                                    }                            
                                </tr>
                                ) : <LoadingTable colspan="19" />
                            }
                            </tbody>
                        </table>
                    }
                    {
                        this.state.detailView &&
                        <table className="table table-hover mails m-0">
                            <thead>
                                <tr>
                                    <th rowSpan="2" style={{ width:'200px' }}>Name</th>
                                    <th className="center-text" colSpan="2" style={{ width:'100px' }}>Year</th>
                                    <th className="center-text" colSpan="12">Leave</th>
                                </tr>
                                <tr>
                                    <th style={{ width:'50px' }}>{ moment().format("YYYY") }</th>
                                    <th style={{ width:'50px', borderRight:'2px solid #ddd' }}>{ moment().format("YYYY")-1 }</th>
                                {
                                    this.state.months.map((m,index) => <th key={ index } >{ m }</th>)
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.isLoaded ? this.state.leavesByMonth.map((e,index) => 
                                <tr onClick={ () => this.getListLeaveEmployee(e.id) }  key={ index } style={{ cursor:'pointer' }}>
                                    <td>{ e.name }</td>
                                    <td className="center-text">{ e.leave }</td>
                                    <td className="center-text">{ e.remainingDaysOff }</td>
                                    {
                                        this.state.monthsFullname.map((m,index) => 
                                            <td key={ index } className="center-text">{ e.leaveList.find( d => d.month ===m) ? e.leaveList.find( d => d.month ===m).total : ' ' }</td>
                                        )
                                    }
                                </tr>
                                ) : <LoadingTable colspan="15" />                                
                            }
                            </tbody>
                        </table>
                    }
                    <Pagination totalPages={ this.state.pages } onChange={ this.onPageChanges } />
                </div>
                }
                    
                {
                    this.state.showModal ? 
                    <Modal id="addLeave" confirmText="Create" modalTitle="Employee Leave" showModal={ this.state.showModal } onClose={ () => { this.setState({ showModal:false }) } } >
                        <Form id="leaveForm" onSubmit={ this.onSubmit }>
                            <LabelM htmlFor="employeeName" label="Name">
                                <select required className="form-control" id="employeeName" name="employeeCode" defaultValue="" onChange={ this.onChange }>
                                <option disabled></option>
                                    { this.state.employeeActive.map( (e,index) => <option key={ index } value={ e.id }>{ e.name }</option>) }
                                </select>
                            </LabelM>
                            <div className="row">
                                <div className="col-md-12">
                                    <button className="btn btn-info btn-rounded pull-right m-l-10" onClick={ this.onAddMoreLeaveDate }>
                                        <i className="ti-plus"></i>
                                    </button>
                                </div>
                            </div>
                            {this.state.leaveDate && this.state.leaveDate.map((data, i) => {
                                return <div className="row" key={i}>
                                    <div className="col-md-10">
                                        <LabelM htmlFor="employeeLeaveDate" label="Leave Date">
                                            <input required type="date" className="form-control" id="employeeLeaveDate" name="leaveDate" onChange={ (event) => this.onChangeLeaveDate(event, i) }/>
                                        </LabelM>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-danger btn-rounded pull-right m-l-10 m-t-30" onClick={ () => this.onDeleteLeaveDate(i) }>
                                            <i className="ti-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            })}
                            <LabelM htmlFor="employeeReason" label="Reason">
                                <input type="text" className="form-control" id="employeeReason" name="reason" onChange={ this.onChange }/>
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </Form>
                    </Modal>
                    : ''
                }

                {
                    this.state.showModalEditLeave ? 
                    <Modal id="addLeave" confirmText="Create" modalTitle="Update Leave" showModal={ this.state.showModalEditLeave } onClose={ () => { this.setState({ showModalEditLeave:false }) } } >
                    <Form id="updayeForm" onSubmit={ this.onUpdateLeave }>
                            <div className="row" >
                                <div className="col-md-10">
                                    <LabelM htmlFor="employeeLeaveDate" label="Leave Date">
                                        <input required type="date" className="form-control" id="employeeLeaveDate" name="dateUpdate"  onChange={ (event) => this.onChangeUpdateLeaveDate(event) }/>
                                    </LabelM>
                                </div>
                                
                            </div>
                        
                            <LabelM htmlFor="employeeReason" label="Reason">
                                <input type="text" className="form-control" id="employeeReasonL" name="reasonUpdate" value={this.state.reasonUpdate}  onChange={ this.onChange }/>
                            </LabelM>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                    </Form>
                </Modal>
                    : ''

                }
                {
                    this.state.showConfirmModal
                    ? <Modal modalTitle="Refresh Leave" showModal={ this.state.showConfirmModal } onClose={ () => this.setState({ showConfirmModal:false })} hasConfirm={ true } hasCancel={ true } onConfirm={ this.onRefresh }>
                        <center>Are you sure you want to refresh all of leave?</center>
                    </Modal>
                    : ''
                }
                {
                    this.state.showConfirmModalDeleteLeave
                    ? <Modal modalTitle="Delete Leave" showModal={ this.state.showConfirmModalDeleteLeave } onClose={ () => this.setState({ showConfirmModalDeleteLeave:false })} hasConfirm={ true } hasCancel={ true } onConfirm={   this.onDeleteLeaveByEmployee }>
                        <center>Are you sure you want to delete leave?</center>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default Leave;