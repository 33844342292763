import React, { Component } from 'react';

class LoadingTable extends Component {
    render () {
        return (
            <tr><td align="center" colSpan={this.props.colspan} style={{verticalAlign:'middle',height:'368px'}}>Getting your data...</td></tr>
        )
    }
}

export default LoadingTable;