import React from 'react';
import Tabs from '../../Component/Tabs';
import Modal from '../../Component/Modal';
import LabelH from '../../Component/LabelH';
import moment from 'moment';
import service from './service';
import CircleText from '../../Component/CircleText';
import CirclePhoto from '../../Component/CirclePhoto';

/*
    TODO:
    > Map response employee role,status,level,title to Update payload (ex Manager to ER001)
    >> [DONE] with note highly dependent with different backend data entities
    > Integrate with BE (UPDATE Data)
    >> [DONE]
    > Integrate with BE (DELETE Data)
    >> [DONE]
    > Show modal when data will be deleted
    >> [DONE]
*/
class EmployeeDetail extends React.Component {
    state = {
        showDeleteModal : false,
        redirect : false,
        version: 0,
        name: "",
        acronymName: '',
        gender: "male",
        religion: "",
        religionOption: [],
        bloodType: "",
        bloodTypeOption: [],
        birthPlace : "",
        birthDate : 0,
        handphone: "",
        email: "",
        address: "",
        education: "",
        university: "",
        educationOption: [],
        emailOffice: "",
        joinDate : 0,
        permanentDate : 0,
        mopDate: 0,
        level: "",
        levelOption : [],
        role: "",
        roleOption : [],
        status: "",
        statusOption : [],
        title: "",
        titleOption : [],
        laptop: "",
        laptopOption: [],
        accountNumber: "",
        accountName: "",
        ktp: "",
        kk: "",
        bpjsHealth: "",
        bpjsLabor: "",
        npwp: "",
        emergencyContact: "",
        emergencyCall: "",
        children: 0,
        childrenOption: [0,1,2,3],
        marriedCode: "",
        marital: "",
        maritalOption: [],
        spouseName: "",
        spouseBirthDate: 0,
        children1Name: "",
        children1BirthDate: 0,
        children1Gender: "male",
        children2Name: "",
        children2BirthDate: 0,
        children2Gender: "male",
        children3Name: "",
        children3BirthDate: 0,
        children3Gender: "male",
        photoProfile: '',
        fileKTP: '',
        fileKK: '',
        fileNPWP: '',
        fileBPJSTK: '',
        fileBPJSKes: '',
        fileSK: '',
    }

    onChange = (event) => {
        const target = event.target;
        const name = target.name;
        let isSuccess = 1;
        let message = '';
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            case 'file':
                const data = new FormData();
                data.append('file', target.files[0]);
                data.append('fileType', target.name);
                data.append('id', this.props.match.params.id);

                if (target.files[0].size > 1000000) {
                    isSuccess = 0;
                    message = 'Please upload less than 1 MB'
                } else {
                    service.uploadFile(data)
                    .then(res => {
                        if(res.status===200){
                            value = res.data.result;
                            this.setState({
                                [name]: value
                            });
                        } else {
                            console.log(res)  
                        }
                    })
                    .catch(err => {
                        alert("Failed");
                    })
                }

                break;
            default :
                value = target.value;
        }

        if (isSuccess) {
            this.setState({
                [name]: value
            });
        } else {
            alert(message)
        }
    }

    onDelete = (event) => {
        event.preventDefault();
        service.deleteEmployee(this.props.match.params.id)
        .then(res => {
            if(res.status===200){
                // display modal good
                alert("Data Deleted")
                this.props.history.goBack();
            } else {
                // display modal bad
                alert("Failed")
            }
        })
        .catch(err => {
            alert("Failed");
        })
    }

    onSubmit = (event) => {
        // Integrate with BE
        event.preventDefault();
        service.putEmployee({
            id: this.props.match.params.id,
            version: this.state.version,
            name: this.state.name,
            blood_type: this.state.bloodTypeCode ? this.state.bloodTypeCode : this.state.bloodType ? this.state.bloodTypeOption.find(e => e.name === this.state.bloodType).code : null,
            religion: this.state.religionCode ? this.state.religionCode : this.state.religion ? this.state.religionOption.find(e => e.name === this.state.religion).code : null,
            birth_place: this.state.birthPlace,
            birth_date: this.state.birthDate,
            handphone: this.state.handphone,
            email: this.state.email,
            email_office: this.state.emailOffice,
            address: this.state.address,
            education: this.state.educationCode ? this.state.educationCode : this.state.education ? this.state.educationOption.find(e => e.name === this.state.education).code : null,
            university: this.state.university,
            is_male: this.state.gender==="male",
            join_date: this.state.joinDate,
            permanent_date: this.state.permanentDate,
            mop_date: this.state.mopDate,
            level: this.state.levelCode ? this.state.levelCode : this.state.level ? this.state.levelOption.find(e => e.name === this.state.level).code : null,
            role: this.state.roleCode ? this.state.roleCode : this.state.role ? this.state.roleOption.find(e => e.name === this.state.role).code : null, 
            status: this.state.statusCode ? this.state.statusCode : this.state.status ? this.state.statusOption.find(e => e.name === this.state.status).code : null,
            title: this.state.titleCode ? this.state.titleCode : this.state.title ? this.state.titleOption.find(e => e.name === this.state.title).code : null,
            laptop: this.state.laptopCode ? this.state.laptopCode : this.state.laptop ? this.state.laptopOption.find(e => e.name === this.state.laptop).code : null,
            account_number: this.state.accountNumber,
            account_name: this.state.accountName,
            ktp: this.state.ktp,
            kk: this.state.kk,
            bpjs_health: this.state.bpjsHealth,
            bpjs_labor: this.state.bpjsLabor,
            npwp: this.state.npwp,
            emergency_contact: this.state.emergencyContact,
            emergency_call: this.state.emergencyCall,
            children: this.state.children ? this.state.children : this.state.children ? this.state.childrenOption.find(e => e === this.state.children) : null,
            marital: this.state.maritalCode,
            spouse_name: this.state.spouseName,
            spouse_birth_date: this.state.spouseBirthDate,
            children_1_name: this.state.children1Name,
            children_1_birth_date: this.state.children1BirthDate,
            children_1_gender: this.state.children1Gender==="male",
            children_2_name: this.state.children2Name,
            children_2_birth_date: this.state.children2BirthDate,
            children_2_gender: this.state.children2Gender==="male",
            children_3_name: this.state.children3Name,
            children_3_birth_date: this.state.children3BirthDate,
            children_3_gender: this.state.children3Gender==="male",
        }, this.props.match.params.id)
        .then(res => {
            if(res.status===200){
                // display modal good
                alert("Success")
                this.props.history.goBack();
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch(err => {
            alert("Fail");
        });
    }

    componentDidMount(){
        service.getEmployeeById(this.props.match.params.id)
            .then((res) => {
                let documents = res.data.result.Document;
                documents.map((data) => {
                    if (data.fileType === 'fileKTP') {
                        this.setState({fileKTP: data.url});
                    } else if (data.fileType === 'fileKK') {
                        this.setState({fileKK: data.url});
                    } else if (data.fileType === 'fileNPWP') {
                        this.setState({fileNPWP: data.url});
                    } else if (data.fileType === 'fileBPJSTK') {
                        this.setState({fileBPJSTK: data.url});
                    } else if (data.fileType === 'fileBPJSKes') {
                        this.setState({fileBPJSKes: data.url});
                    } else if (data.fileType === 'photoProfile') {
                        this.setState({photoProfile: data.url});
                    } else if (data.fileType === 'fileSK') {
                        this.setState({fileSK: data.url});
                    }
                    return data;
                })

                let str = res.data.result.name;
                var matches = str.match(/\b(\w)/g);
                var acronym = matches.join('');

                this.setState({
                    version: res.data.result.version,
                    name: res.data.result.name,
                    acronymName: acronym,
                    religion: res.data.result.religion,
                    bloodType: res.data.result.blood_type,
                    birthPlace: res.data.result.birth_place ? res.data.result.birth_place : this.state.birthPlace,
                    birthDate: res.data.result.birth_date,
                    handphone: res.data.result.handphone ? res.data.result.handphone : this.state.handphone,
                    email: res.data.result.email ? res.data.result.email : this.state.email,
                    address: res.data.result.address ? res.data.result.address : this.state.address,
                    education: res.data.result.education,
                    university: res.data.result.university ? res.data.result.university : this.state.university,
                    joinDate: res.data.result.join_date,
                    permanentDate: res.data.result.permanent_date,
                    mopDate: res.data.result.mop_date,
                    gender: res.data.result.is_male ? "male" : "female",
                    emailOffice: res.data.result.email_office ? res.data.result.email_office : this.state.emailOffice,
                    level: res.data.result.level,
                    role: res.data.result.role,
                    status: res.data.result.status,
                    title: res.data.result.title,
                    laptop: res.data.result.laptop,
                    accountNumber: res.data.result.account_number ? res.data.result.account_number : this.state.accountNumber,
                    accountName: res.data.result.account_name ? res.data.result.account_name : this.state.accountName,
                    ktp: res.data.result.ktp ? res.data.result.ktp : this.state.ktp,
                    kk: res.data.result.kk ? res.data.result.kk : this.state.kk,
                    bpjsHealth: res.data.result.bpjs_health ? res.data.result.bpjs_health : this.state.bpjsHealth,
                    bpjsLabor: res.data.result.bpjs_labor ? res.data.result.bpjs_labor : this.state.bpjsLabor,
                    npwp: res.data.result.npwp ? res.data.result.npwp : this.state.npwp,
                    emergencyContact: res.data.result.emergency_contact ? res.data.result.emergency_contact : this.state.emergencyContact,
                    emergencyCall: res.data.result.emergency_call ? res.data.result.emergency_call : this.state.emergencyCall,
                    children: res.data.result.children,
                    marital: res.data.result.marital,
                    maritalCode: res.data.result.marital_code,
                    spouseName: res.data.result.spouse_name ? res.data.result.spouse_name : this.state.spouseName,
                    spouseBirthDate: res.data.result.spouse_birth_date,
                    children1Name: res.data.result.children_1_name ? res.data.result.children_1_name : this.state.children1Name,
                    children1BirthDate: res.data.result.children_1_birth_date,
                    children1Gender: res.data.result.children_1_gender ? "male" : "female",
                    children2Name: res.data.result.children_2_name ? res.data.result.children_2_name : this.state.children2Name,
                    children2BirthDate: res.data.result.children_2_birth_date,
                    children2Gender: res.data.result.children_2_gender ? "male" : "female",
                    children3Name: res.data.result.children_3_name ? res.data.result.children_3_name : this.state.children3Name,
                    children3BirthDate: res.data.result.children_3_birth_date,
                    children3Gender: res.data.result.children_3_gender ? "male" : "female",
                })
            });
        service.getReligions()
            .then((res) => {
                this.setState({
                    religionOption : [...res.data.result]
                })
            });
        service.getBloodTypes()
            .then((res) => {
                this.setState({
                    bloodTypeOption : [...res.data.result]
                })
            });
        service.getEducations()
            .then((res) => {
                this.setState({
                    educationOption : [...res.data.result]
                })
            });
        service.getTitleParameters()
            .then((res) => {
                this.setState({
                    titleOption : [...res.data.result]
                })
            });
        service.getRoleParameters()
            .then((res) => this.setState({
                roleOption : [...res.data.result]
            }));
        service.getLevelParameters()
            .then((res) => this.setState({
                levelOption : [...res.data.result]
            }));
        service.getStatusParameters()
            .then((res) => this.setState({
                statusOption : [...res.data.result]
            }));
        service.getStatusLaptops()
            .then((res) => this.setState({
                laptopOption : [...res.data.result]
            }));
        service.getMaritals()
            .then((res) => this.setState({
                maritalOption : [...res.data.result],
                marriedCode : res.data.result.find(e => e.name === 'Married').code
            }));
}

    render(){
        return(
            <div>
                <h4 className="inline">Employee Data</h4>
                <button onClick={ (event) => {event.preventDefault();this.setState({ showDeleteModal:true })} } className="btn btn-danger btn-rounded pull-right m-l-10">
                    <i className="ti-trash"></i>                
                </button>
                <button onClick={ this.onSubmit } className="btn btn-primary btn-rounded pull-right">
                    <i className="ti-save"></i>
                </button>
                <form onSubmit={ this.onSubmit } className="form-horizontal">
                    <Tabs>
                        <Tabs.Pane isActive={ true } tabs="Personal" anchor="personal">
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelH htmlFor="employeeName" label="Name">
                                        <input required type="text" className="form-control" id="employeeName" name="name" value={ this.state.name } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeReligion" label="Religion">
                                        <select className="form-control" id="employeeReligion" name="religionCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.religion }</option>
                                            { this.state.religionOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="employeeBirthPlace" label="Birth Place">
                                        <input type="text" className="form-control" id="employeeBirthPlace" name="birthPlace" value={ this.state.birthPlace } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeePhone" label="HP">
                                        <input type="text" className="form-control" id="employeePhone" name="handphone" value={ this.state.handphone } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeAddress" label="Address">
                                        <textarea rows="4" id="employeeAddress" className="form-control" name="address" value={ this.state.address } onChange={ this.onChange } />
                                    </LabelH>
                                    <LabelH htmlFor="employeeEducation" label="Education">
                                        <select className="form-control" id="employeeEducation" name="educationCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.education }</option>
                                            { this.state.educationOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                </div>
                                <div className="col-md-6">
                                    <LabelH htmlFor="employeeGenderRadio" label="Gender">
                                        <label className="radio-inline">
                                            <input type="radio" name="gender" value="male" checked={ this.state.gender==="male" } onChange={ this.onChange } /> Male
                                        </label>
                                        <label className="radio-inline">
                                            <input type="radio" name="gender" value="female" checked={ this.state.gender==="female" } onChange={ this.onChange } /> Female
                                        </label>
                                    </LabelH>
                                    <LabelH htmlFor="employeeBlood" label="Blood Type">
                                        <select className="form-control" id="employeeBlood" name="bloodTypeCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.bloodType }</option>
                                            { this.state.bloodTypeOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="birthDate" label="Birth Date">
                                        <input type="date" className="form-control" name="birthDate" value={ this.state.birthDate ? moment(this.state.birthDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                    </LabelH>
                                    <LabelH htmlFor="employeeMail" label="E-Mail">
                                        <input type="text" className="form-control" id="employeeMail" name="email" value={ this.state.email } onChange={ this.onChange }/>
                                    </LabelH>
                                    <div style={{ marginTop:'123px' }}>
                                        <LabelH htmlFor="employeeUniversity" label="Universitas">
                                            <input type="text" className="form-control" id="employeeUniversity" name="university" value={ this.state.university } onChange={ this.onChange }/>
                                        </LabelH>
                                    </div>
                                </div>
                            </div>
                        </Tabs.Pane>
                        <Tabs.Pane tabs="Office" anchor="office">
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelH htmlFor="employeeMail" label="E-Mail">
                                        <input type="text" className="form-control" id="employeeMail" name="emailOffice" value={ this.state.emailOffice } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeStatus" label="Status">
                                        <select className="form-control" id="employeeStatus" name="statusCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.status }</option>
                                            { this.state.statusOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="employeeLaptop" label="Laptop">
                                        <select className="form-control" id="employeeLaptop" name="laptopCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.laptop }</option>
                                            { this.state.laptopOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="employeeTitle" label="Title">
                                        <select className="form-control" id="employeeTitle" name="titleCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.title }</option>
                                            { this.state.titleOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="employeeLevel" label="Level">
                                        <select className="form-control" id="employeeLevel" name="levelCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.level }</option>
                                            { this.state.levelOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <LabelH htmlFor="employeeAccountNumber" label="Account Number">
                                        <div className="input-group">
                                            <span className="input-group-addon b-0">BCA</span> 
                                            <input type="text" className="form-control" id="employeeAccountNumber" name="accountNumber" value={ this.state.accountNumber } onChange={ this.onChange }/>
                                        </div>
                                    </LabelH>
                                </div>
                                <div className="col-md-6">
                                    <LabelH htmlFor="joinDate" label="Join Date">
                                        <input required type="date" className="form-control" name="joinDate" value={ this.state.joinDate ? moment(this.state.joinDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                    </LabelH>
                                    <LabelH htmlFor="permanentDate" label="Permanent Date">
                                        <input required type="date" className="form-control" name="permanentDate" value={ this.state.permanentDate ? moment(this.state.permanentDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                    </LabelH>
                                    <LabelH htmlFor="mopDate" label="MOP Date">
                                        <input required type="date" className="form-control" name="mopDate" value={ this.state.mopDate ? moment(this.state.mopDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                    </LabelH>
                                    <LabelH htmlFor="employeeRole" label="Role">
                                        <select className="form-control" id="employeeRole" name="roleCode" defaultValue="" onChange={ this.onChange }>
                                            <option disabled>{ this.state.role }</option>
                                            { this.state.roleOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                        </select>
                                    </LabelH>
                                    <div style={{ marginTop:'67px' }}>
                                        <LabelH htmlFor="employeeAccountName" label="Account Name">
                                            <input type="text" className="form-control" id="employeeAccountName" name="accountName" value={ this.state.accountName } onChange={ this.onChange }/>
                                        </LabelH>
                                    </div>
                                </div>
                            </div>
                        </Tabs.Pane>
                        <Tabs.Pane tabs="Document" anchor="document">
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelH htmlFor="employeeKTP" label="KTP">
                                        <input type="text" className="form-control" id="employeeKTP" name="ktp" value={ this.state.ktp } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeBPJSKes" label="BPJS Kes">
                                        <input type="text" className="form-control" id="employeeBPJSKes" name="bpjsHealth" value={ this.state.bpjsHealth } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeNPWP" label="NPWP">
                                        <input type="text" className="form-control" id="employeeNPWP" name="npwp" value={ this.state.npwp } onChange={ this.onChange }/>
                                    </LabelH>
                                </div>
                                <div className="col-md-6">
                                    <LabelH htmlFor="employeeKK" label="KK">
                                        <input type="text" className="form-control" id="employeeKK" name="kk" value={ this.state.kk } onChange={ this.onChange }/>
                                    </LabelH>
                                    <LabelH htmlFor="employeeBPJTK" label="BPJS TK">
                                        <input type="text" className="form-control" id="employeeBPJTK" name="bpjsLabor" value={ this.state.bpjsLabor } onChange={ this.onChange }/>
                                    </LabelH>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelH htmlFor="photoProfile" label="Photo Profile">
                                        {this.state.photoProfile ? <CirclePhoto size={'100'} src={this.state.photoProfile} /> : <CircleText size={'100'} name={this.state.acronymName} />}
                                        {this.state.photoProfile && <a href={this.state.photoProfile} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="photoProfile" accept="image/*" onChange={ this.onChange } style={{ marginTop:'10px' }}/>
                                    </LabelH>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelH htmlFor="fileKTP" label="File KTP">
                                        {this.state.fileKTP && <a href={this.state.fileKTP} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileKTP" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                    <LabelH htmlFor="fileKK" label="File KK">
                                        {this.state.fileKK && <a href={this.state.fileKK} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileKK" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                    <LabelH htmlFor="fileNPWP" label="File NPWP">
                                        {this.state.fileNPWP && <a href={this.state.fileNPWP} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileNPWP" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                </div>
                                <div className="col-md-6">
                                    <LabelH htmlFor="fileBPJSTK" label="File BPJS TK">
                                        {this.state.fileBPJSTK && <a href={this.state.fileBPJSTK} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileBPJSTK" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                    <LabelH htmlFor="fileBPJSKes" label="File BPJS Kes">
                                        {this.state.fileBPJSKes && <a href={this.state.fileBPJSKes} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileBPJSKes" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                    <LabelH htmlFor="fileSK" label="File SK Pengangkatan">
                                        {this.state.fileSK && <a href={this.state.fileSK} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-rounded pull-right"><i className="fa fa-eye"></i></a>}
                                        <input type="file" name="fileSK" accept="application/pdf" onChange={ this.onChange } style={{ marginTop:'7px' }}/>
                                    </LabelH>
                                </div>
                            </div>
                        </Tabs.Pane>
                        <Tabs.Pane tabs="Family" anchor="family">
                            <div className="col-md-6">
                                <LabelH htmlFor="employeeEmergencyContact" label="Emergency">
                                    <input type="text" className="form-control" id="employeeEmergencyContact" name="emergencyContact" value={ this.state.emergencyContact } onChange={ this.onChange }/>
                                </LabelH>
                                <LabelH htmlFor="employeeMarriage" label="Status">
                                    <select className="form-control" id="employeeMarriage" name="maritalCode" defaultValue="" onChange={ this.onChange }>
                                        <option disabled>{ this.state.marital }</option>
                                        { this.state.maritalOption.map( (e,index) => <option key={ index } value={ e.code }>{ e.name }</option>) }
                                    </select>
                                </LabelH>
                                {
                                    this.state.maritalCode===this.state.marriedCode &&
                                    <LabelH htmlFor="employeeSpouse" label="Spouse Name">
                                        <input type="text" className="form-control" id="employeeSpouse" name="spouseName" value={ this.state.spouseName } onChange={ this.onChange }/>
                                    </LabelH>
                                }
                                {
                                    this.state.children > 0 &&
                                    <div>
                                        <LabelH htmlFor="employee1Child" label="Child Name">
                                            <input type="text" className="form-control" id="employee1Child"  name="children1Name" value={ this.state.children1Name } onChange={ this.onChange }/>
                                        </LabelH>
                                        <LabelH htmlFor="employee1ChildGender" label="Gender">
                                            <label className="radio-inline">
                                                <input type="radio" name="children1Gender" value="male" checked={ this.state.children1Gender==="male" } onChange={ this.onChange } /> Male
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio" name="children1Gender" value="female" checked={ this.state.children1Gender==="female" } onChange={ this.onChange } /> Female
                                            </label>
                                        </LabelH>
                                    </div>
                            }
                                {
                                    this.state.children > 1 &&
                                    <div>
                                        <LabelH htmlFor="employee2Child" label="Child Name">
                                            <input type="text" className="form-control" id="employee2Child"  name="children2Name" value={ this.state.children2Name } onChange={ this.onChange }/>
                                        </LabelH>
                                        <LabelH htmlFor="employee2ChildGender" label="Gender">
                                            <label className="radio-inline">
                                                <input type="radio" name="children2Gender" value="male" checked={ this.state.children2Gender==="male" } onChange={ this.onChange } /> Male
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio" name="children2Gender" value="female" checked={ this.state.children2Gender==="female" } onChange={ this.onChange } /> Female
                                            </label>
                                        </LabelH>
                                    </div>
                                }
                                {
                                    this.state.children > 2 &&
                                    <div>
                                        <LabelH htmlFor="employee3Child" label="Child Name">
                                            <input type="text" className="form-control" id="employee3Child"  name="children3Name" value={ this.state.children3Name } onChange={ this.onChange }/>
                                        </LabelH>
                                        <LabelH htmlFor="employee3ChildGender" label="Gender">
                                            <label className="radio-inline">
                                                <input type="radio" name="children3Gender" value="male" checked={ this.state.children3Gender==="male" } onChange={ this.onChange } /> Male
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio" name="children3Gender" value="female" checked={ this.state.children3Gender==="female" } onChange={ this.onChange } /> Female
                                            </label>
                                        </LabelH>
                                    </div>
                            }
                            </div>
                            <div className="col-md-6">
                                <LabelH htmlFor="employeeEmergencyCall" label="HP">
                                    <input type="text" className="form-control" id="employeeEmergencyCall" name="emergencyCall" value={ this.state.emergencyCall } onChange={ this.onChange }/>
                                </LabelH>
                                <LabelH htmlFor="employeeChildren" label="Children">
                                    <select className="form-control" id="employeeChildren" name="children" defaultValue="" onChange={ this.onChange }>
                                        <option>{ this.state.children }</option>
                                        { this.state.childrenOption.map( (e,index) => <option key={ index } value={ e }>{ e }</option>) }
                                    </select>
                                </LabelH>
                                {
                                    this.state.maritalCode===this.state.marriedCode &&
                                    <LabelH htmlFor="spouseBirthDate" label="Birth Date">
                                        <input type="date" className="form-control" name="spouseBirthDate" value={ this.state.spouseBirthDate ? moment(this.state.spouseBirthDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                    </LabelH>
                                }
                                {
                                    this.state.children > 0 &&
                                    <div className="m-b-69">
                                        <LabelH htmlFor="employee1ChildBirthDate" label="Birth Date">
                                            <input type="date" className="form-control" name="children1BirthDate" value={ this.state.children1BirthDate ? moment(this.state.children1BirthDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                        </LabelH>
                                    </div>
                                }
                                {
                                    this.state.children > 1 &&
                                    <div className="m-b-69">
                                        <LabelH htmlFor="employee2ChildBirthDate" label="Birth Date">
                                            <input type="date" className="form-control" name="children2BirthDate" value={ this.state.children2BirthDate ? moment(this.state.children2BirthDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                        </LabelH>
                                    </div>
                                }
                                {
                                    this.state.children > 2 &&
                                    <div className="m-b-69">
                                        <LabelH htmlFor="employee3ChildBirthDate" label="Birth Date">
                                            <input type="date" className="form-control" name="children3BirthDate" value={ this.state.children3BirthDate ? moment(this.state.children3BirthDate).format("YYYY-MM-DD") : '' } onChange={ this.onChange } />
                                        </LabelH>
                                    </div>
                                }
                            </div>
                        </Tabs.Pane>
                    </Tabs>
                </form>
                {
                    this.state.showDeleteModal
                    ? <Modal modalTitle="Delete Employee" showModal={ this.state.showDeleteModal } onClose={ () => this.setState({ showDeleteModal:false })} hasConfirm={ true } hasCancel={ true } onConfirm={ this.onDelete }>
                        <center>Are you sure you want to delete <b>{ this.state.name }</b> ?</center>
                    </Modal>
                    : ''
                }
            </div>
        )
    }
}

export default EmployeeDetail;