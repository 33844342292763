import React, { Component } from 'react';
import service from './Employee/service';
import Form from '../Component/Form';
import LabelM from '../Component/LabelM';

class TaxCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grossSalary : 0,
            mop : 0,
            ptkpStatus : 'TK_0',
            listPtkpStatus : [
                'TK_0',
                'TK_1',
                'TK_2',
                'TK_3',
                'K_0',
                'K_1',
                'K_2',
                'K_3',
                'KI_0',
                'KI_1',
                'KI_2',
                'KI_3'
            ],
            response : {},
        }
    } 
    
    onSubmit = (event) => {
        // Integrate with BE
        service.getTaxCalculation({
            grossSalary: this.state.grossSalary,
            mop: this.state.mop,
            ptkpStatus: this.state.ptkpStatus
        })
        .then((res) => {
            if(res.status===200){
                // display modal good
                this.setState({response: res.data})
                console.log(res.data)
            } else {
                // display modal bad
                alert("Fail")
            }
        })
        .catch((err) => {
            alert("Fail");
        });
    }
    onChange = (event) => {
        const target = event.target;
        let value;
        switch(target.type){
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = new Date(target.value).getTime();
                break;
            case 'select-one':
                value = target.options[target.selectedIndex].value;
                break;
            default :
                value = target.value;
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    render (){
        return (
            <div>
                <h4 className="inline">Tax Calculator</h4>
                
                    <Form id="calculatorForm" onSubmit={ this.onSubmit }>
                        <LabelM htmlFor="grossSalary" label="Gaji Kotor">
                            <input required type="number" className="form-control" id="grossSalary" name="grossSalary" defaultValue="0" onChange={ this.onChange }/>
                        </LabelM>
                        <LabelM htmlFor="mop" label="MOP">
                            <input required type="number" className="form-control" id="mop" name="mop" defaultValue="0" onChange={ this.onChange }/>
                        </LabelM>
                        <LabelM htmlFor="ptkpStatus" label="PTKP STATUS">
                            <select required className="form-control" id="ptkpStatus" name="ptkpStatus" defaultValue="TK_0" onChange={ this.onChange }>
                                <option disabled></option>
                                { this.state.listPtkpStatus.map( (e,index) => <option key={ index } value={ e }>{ e }</option>) }
                            </select>                            
                        </LabelM>
                        <div className="modal-footer">
                            <button type="calculate" className="btn btn-primary">Calculate</button>
                        </div>
                    </Form>
                    <LabelM htmlFor="bpjsBill" label="BPJS-TK">
                        <input required type="text" className="form-control" id="bpjsBill" name="bpjsBill" defaultValue="0" value={
                            new Intl.NumberFormat('id-IN',{
                                style: 'currency', currency: 'IDR', maximumSignificantDigits: 3
                            }).format(this.state.response.bpjsBill|0)
                        }/>
                    </LabelM>
                    <LabelM htmlFor="taxEstimate" label="Pajak PPH21">
                        <input required type="text" className="form-control" id="taxEstimate" name="taxEstimate" defaultValue="0" value={
                            new Intl.NumberFormat('id-IN',{
                                style: 'currency', currency: 'IDR', maximumSignificantDigits: 3
                            }).format(this.state.response.monthlyTaxEstimate|0)
                        }/>
                    </LabelM>

                    <LabelM htmlFor="nettSalary" label="Gaji Bersih">
                        <input required type="text" className="form-control" id="nettSalary" name="nettSalary" defaultValue="0" value={
                            new Intl.NumberFormat('id-IN',{
                                style: 'currency', currency: 'IDR',maximumSignificantDigits: 3
                            }).format(this.state.response.nettSalary|0)
                        }/>
                    </LabelM>
            </div>
        )
    }
}

export default TaxCalculator;