import WebService from '../../WebService';
import URL from '../../config/urls';

const deleteEmployee = (id) => {
    return WebService.delete(`${ URL.EMPLOYEE }/${ id }`)
}

const getEmployeeActive = (params) => {
    return WebService.get(`${URL.EMPLOYEE}/${ URL.ACTIVE }`, {
        params : params
    });
}

const getEmployeeResign = (params) => {
    return WebService.get(`${URL.EMPLOYEE}/${ URL.RESIGN }`, {
        params : params
    });
}

const getEmployeeLeaves = (params) => {
    return WebService.get(`${URL.LEAVE}/${ URL.LEAVE }`, {
        params : params
    })
}

const getLeaveListDetail = (params) => {
    return WebService.get(`${URL.LEAVE}/${ URL.LEAVE_DETAIL }`, {
        params :params
    })
}

const getEmployeeLeavesByMonth = (params) => {
    return WebService.get(`${URL.LEAVE}/${ URL.LEAVE_MONTH }`, {
        params : params
    })
}

const getEmployeeBirthday = () => {
    return WebService.get(`${URL.EMPLOYEE}/${ URL.BIRTHDAY }`,);
}

const getUnassignedEmployee = () => {
    return WebService.get(`${URL.EMPLOYEE}/${ URL.UNASSIGNED }`,);
}

const getEmployeeById = (id) => {
    return WebService.get(`${ URL.EMPLOYEE }/${ id }`);
}

const getReligions = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'RG',
            limit : 9999
         }
    })
}

const getBloodTypes = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'BT',
            limit : 9999
         }
    })
}

const getEducations = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'PD',
            limit : 9999
         }
    })
}

const getStatusLaptops = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'LP',
            limit : 9999
         }
    })
}

const getStatusParameters = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'ES',
            limit : 9999
         }
    })
}

const getRoleParameters = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'PR',
            limit : 9999
         }
    })
}

const getLevelParameters = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'EL',
            limit : 9999
         }
    })
}

const getTitleParameters = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'ED',
            limit : 9999
         }
    })
}

const getMaritals = () => {
    return WebService.get(URL.MASTER_LOV, {
        params : { 
            searchBy : 'code',
            searchVal : 'MS',
            limit : 9999
         }
    })
}

const getSales = () => {
    return WebService.get(`${URL.EMPLOYEE}/${ URL.ROLE }`, {
        params : { 
            role : 'PR016'
         }
    })
}

const postEmployee = (data) => {
    return WebService.post(URL.EMPLOYEE, data);
}

const postResignEmployee = (data) => {
    return WebService.post(`${URL.EMPLOYEE}/${ URL.RESIGN }`, data);
}

const postLeave = (data) => {
    return WebService.post(`${URL.LEAVE}/${ URL.ADD }`, data);
}

const putEmployee = (data,id) => {
    return WebService.put(`${ URL.EMPLOYEE }/${ id }`, data);
}

const uploadFile = (data) => {
    return WebService.post(`${ URL.DOCUMENT }/${ URL.UPLOAD }`, data);
}

const refreshLeave = () => {
    return WebService.post(`${ URL.LEAVE }/${ URL.DELETE_LEAVE }`);
}

const deleteLeaveByEmployee = (params) => {
    return WebService.delete(`${ URL.LEAVE }/${URL.DELETE_LEAVE_BY_EMPLOYEE}`,{
        params:params
    })
}

const updateLeaveByEmployee = (data) => {
    return WebService.post(`${ URL.LEAVE }/${URL.UPDATE_LEAVE_BY_EMPLOYEE}`, data)
}

const getTaxCalculation = (data) => {
    return WebService.post(`${ URL.TAX }`, data)
}

export default {getEmployeeActive,getEmployeeResign,
    getEmployeeLeaves,getEmployeeLeavesByMonth,getEmployeeBirthday,getUnassignedEmployee,getEmployeeById,
    postEmployee,postResignEmployee,postLeave,putEmployee,deleteEmployee,
    getReligions,getBloodTypes,getEducations,getStatusLaptops,getLevelParameters,getRoleParameters,getStatusParameters,getTitleParameters,getMaritals,getSales,uploadFile,refreshLeave,getLeaveListDetail,deleteLeaveByEmployee,updateLeaveByEmployee,getTaxCalculation};